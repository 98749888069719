<template>
  <Maintenance v-if="isMaintenance" />
  <NuxtLayout v-else>
    <NuxtPage />
    <ClientOnly>
      <TransitionGroup tag="div" name="show-modal">
        <Cart
          v-if="isActiveModal(cartModal)"
          @close="modalStore.toggleModal(cartModal)"
        />
        <PreOrderModal
          v-if="isActiveModal(preOrderModal)"
          @close="modalStore.toggleModal(preOrderModal)"
        />
        <SuccessfulModal
          v-if="isActiveModal(successfulModal)"
          @close="modalStore.toggleModal(successfulModal)"
        />
        <UnsuccessfulModal
          v-if="isActiveModal(unsuccessfulModal)"
          @close="modalStore.toggleModal(unsuccessfulModal)"
        />
        <AccountModal
          v-if="isActiveModal(accountModal)"
          @close="modalStore.toggleModal(accountModal)"
        />
        <SetNewPassword
          v-if="isActiveModal(setNewPasswordModal)"
          @close="modalStore.toggleModal(setNewPasswordModal)"
        />
        <AutonomicSubscriptionModal
          v-if="isActiveModal(autonomicSubscriptionModal)"
          @close="modalStore.toggleModal(autonomicSubscriptionModal)"
        />
        <ReviewModal
          v-if="isActiveModal(reviewModal)"
          @close="modalStore.toggleModal(reviewModal)"
        />
      </TransitionGroup>
      <WidgetHelper />
    </ClientOnly>
  </NuxtLayout>
</template>

<script setup>
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { useUtm } from "~/uses/useUtm";
import {
  accountModal,
  autonomicSubscriptionModal,
  cartModal,
  preOrderModal,
  reviewModal,
  setNewPasswordModal,
  successfulModal,
  unsuccessfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { useMaintenanceStore } from "~/store/maintenanceStore";
import Maintenance from "~/modules/maintenance/Maintenance.vue";
import { useAPI } from "~/uses/useMyFetch";
import { useUserStore } from "~/store/userStore";
import SetNewPassword from "~/modules/account/components/sign/SetNewPassword.vue";
import AccountModal from "~/modules/account/components/sign/AccountModal.vue";
import PreOrderModal from "~/modules/shared/modals/PreOrderModal.vue";
import AutonomicSubscriptionModal from "~/modules/shared/modals/AutonomicSubscriptionModal.vue";
import UnsuccessfulModal from "~/modules/shared/modals/components/UnsuccessfulModal.vue";
import WidgetHelper from "~/modules/widgetHelper/WidgetHelper.vue";
import ReviewModal from "~/modules/shared/modals/ReviewModal.vue";
import SuccessfulModal from "~/modules/shared/modals/components/SuccessfulModal.vue";
import Cart from "~/modules/cart/components/Cart.vue";

const config = useRuntimeConfig();
const route = useRoute();

const languageStore = useLanguageFilterStore();
const userStore = useUserStore();
const modalStore = useModalStore();
const maintenanceStore = useMaintenanceStore();

const { getIsEnabled: isMaintenance } = storeToRefs(maintenanceStore);
const { getUserLanguage } = storeToRefs(languageStore);

const { isActiveModal } = storeToRefs(modalStore);

const isRouteMatched = (name) => {
  return name === route.name.replace(/^[a-z]+_/, "");
};

onMounted(() => {
  if (import.meta.client) {
    userStore.fetch().finally(() => {
      const utm = useUtm(route.query);

      if (utm.isExists) {
        useAPI("/account/utm", {
          method: "POST",
          body: { ...utm.parameters },
        });
      }

      if (route.query.recovery) {
        modalStore.toggleModal(setNewPasswordModal);
      }
    });
  }
});

//Language settings
languageStore.setLanguage(route.name.match(/[^_]*/)[0]);

if (!isMaintenance.value) {
  if (import.meta.client) {
    !(function (t, e, c, n) {
      const s = e.createElement(c);
      (s.async = 1),
        (s.src = "https://statics.esputnik.com/scripts/" + n + ".js");
      const r = e.scripts[0];
      r.parentNode.insertBefore(s, r);
      const f = function () {
        // eslint-disable-next-line prefer-rest-params
        f.c(arguments);
      };
      f.q = [];
      f.c = function () {
        // eslint-disable-next-line prefer-rest-params
        f.q.push(arguments);
      };
      t["eS"] = t["eS"] || f;
    })(window, document, "script", "1CB3725191BF470B801BAEADA65491D0");

    eS("init");
  }

  useHead({
    htmlAttrs: {
      lang: () => getUserLanguage.value.name,
    },
    link: [
      {
        rel: "alternate",
        hreflang: "uk",
        href: () => {
          if (isRouteMatched("home")) {
            return config.public.appUrl;
          }

          if (getUserLanguage.value.name === "ru") {
            return config.public.appUrl + "/ua" + route.path;
          }

          return config.public.appUrl + route.path;
        },
      },
      {
        rel: "alternate",
        hreflang: "ru",
        href: () => {
          if (isRouteMatched("home")) {
            return config.public.appUrl + "/ru";
          }

          if (getUserLanguage.value.name === "uk") {
            const replacedPath = route.path.replace("/ua", "");

            return config.public.appUrl + replacedPath;
          }

          return config.public.appUrl + route.path;
        },
      },
      {
        rel: "canonical",
        href: () => {
          return config.public.appUrl + route.path;
        },
      },
    ],
  });
}
</script>
